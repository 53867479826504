import {
	createStore
} from 'vuex'
import Cookies from 'js-cookie'

export default createStore({
	state: {
		jwt: Cookies.get('WNJWT') ? Cookies.get('WNJWT') : false,
	},
	getters: {
		profile(state){
			if(state.jwt){
				return JSON.parse(atob(state.jwt.split('.')[1])).data
			}else{
				return {};
			}
		}
	},
	mutations: {
		SetUser(state,param){
			let domain = location.host.indexOf('localhost') != -1 ? 'localhost' : location.host.substr(location.host.indexOf('.'))
			state.jwt = param,Cookies.set('WNJWT',param,{domain:domain,expires: 30 });
		},
		Logout(state){
			state.jwt = false,Cookies.remove('WNJWT')
		},
	},
	actions: {},
	modules: {}
})

import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Cookies from 'js-cookie'

const routes = [{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/login',
		name: 'login',
		component: () => import( /* webpackChunkName: "about" */ '../views/LoginView.vue'),
		meta: {
			header: false
		}
	},
	{
		path: '/download',
		name: 'download',
		component: () => import( /* webpackChunkName: "about" */ '../views/DownloadView.vue'),
		meta: {
			header: false
		}
	},
	{
		path: '/center',
		name: 'center',
		component: () => import( /* webpackChunkName: "about" */ '../views/CenterView.vue'),
		meta: {
			header: false
		}
	},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeEach((to, from, next) => {
	window.document.title = to.meta.title == undefined ? '外贸518APP中心' : to.meta.title
	if (to.name != 'login' && to.name != 'home') {
		if (Cookies.get('WNJWT')) {
			next();
		} else {
			next('/login');
		}
	} else {
		next();
	}
})

export default router

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'

const app = createApp(App)
const Api = axios.create({
	baseURL:'/index',
	crossDomain: true,
	withCredentials: true
})

Api.interceptors.request.use(function (config) {
	if(store.state.jwt){
		config.headers = {'jwt': store.state.jwt}
	}
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

Api.interceptors.response.use(
response => {
	return response;
},
error => {
	if (error.response) {
		switch (error.response.status) {
			case 401:
				store.commit('Logout')
				router.push('/login')
		}
	}
	return Promise.reject(error.response.data)
});

app.config.globalProperties.$axios = {...Api}
app.use(store).use(router).use(ViewUIPlus).mount('#app')

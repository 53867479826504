<template>
	<Row class="hero ivu-text-center">
		<Col span="24">
		<Title style="font-size: 64px;"><span style="color: #2b85e4;">Waimao518</span> Browser</Title>
		<Title :level="3">最新版外贸518客户端下载</Title>
		</Col>
	</Row>
	<br>
	<Row>

		<Col span="20" offset="2">
		<Alert type="success" class="ivu-text-center">{{tips}},mac苹果系统暂时无法使用</Alert>
		</Col>
	</Row>
	<br>
	<Row gutter="16">
		<Col span="6" offset="6">
		<div class="version">
			<div class="thumb">
				<Icon type="logo-windows" />
			</div>
			<div class="info">
				<p>位数: win64</p>
				<p>版本: {{x64.version}}</p>
				<Button :to="x64.url">立即下载&nbsp;<Tag color="warning" v-if="x64t">推荐</Tag></Button>
			</div>
		</div>
		</Col>
		<Col span="6">
		<div class="version">
			<div class="thumb">
				<Icon type="logo-windows" />
			</div>
			<div class="info">
				<p>位数: win32</p>
				<p>版本: {{x32.version}}</p>
				<Button :to="x32.url">立即下载&nbsp;<Tag color="warning" v-if="!x64t">推荐</Tag></Button>
			</div>
		</div>
		</Col>
	</Row>
	<br>
</template>

<script>
	export default {
		data() {
			return {
				x64:{},
				x32:{}
			}
		},
		created() {
			var agent = navigator.userAgent.toLowerCase();
			if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
				this.tips = '您的浏览器显示您是32位系统，建议下载32位安装包'
				this.x64t = false;
			} else if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
				this.tips = '您的浏览器显示您是64位系统，建议下载位64安装包'
				this.x64t = true;
			} else {
				this.tips = '未检测到您的系统位数，请根据自身情况选择版本'
			}
			this.$axios.post('/index/get_exe',{arch:'win64'}).then((res) => {
				this.x64 = res.data;
			});
			this.$axios.post('/index/get_exe',{arch:'win32'}).then((res) => {
				this.x32 = res.data;
			});
		}
	}
</script>

<style>
	.hero {
		background-color: rgb(239, 245, 251);
		padding: 80px;
	}

	.version {
		display: flex;
		padding: 10px;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: nowrap;
		flex-direction: row;
		background-color: rgb(239, 245, 251);
		min-width: 280px;
	}

	.version .thumb {
		border: 1px solid #dcdee2;
		padding: 20px;
		background-color: #2d8cf0;
		color: #ffffff;
	}

	.version .ivu-icon {
		font-size: 64px;

	}

	.version .info {
		font-size: 16px;
		margin-left: 16px;
		font-weight: bold;
		line-height: 2em;
	}
</style>
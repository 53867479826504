<template>
	<Button type="info" @click="modal = true" v-if="!IsLogin">
		<Icon type="ios-navigate"></Icon>
		登录
	</Button>
	<Modal class="login" :mask-closable="closable" v-model="modal" :loading="true" title="登录" @on-cancel="handleReset">
			<Form ref="formData" :model="formData" :rules="ruleInline" :label-width="80">
				<FormItem label="用户名" prop="username">
					<Input v-model="formData.username" placeholder="请输入用户名或者手机"></Input>
				</FormItem>
				<FormItem label="密码" prop="password">
					<Input v-model="formData.password" placeholder="请输入密码" type="password"></Input>
				</FormItem>
				<p>使用waimao518官网账号密码登陆，原来的APP账号已经停用，如有问题请联系客服</p>
			</Form>
			<template #footer>
				<Button type="primary" @click="handleSubmit" :loading="loading">
					提交
				</Button>
				<Button @click="handleReset">
					取消
				</Button>
				<Button @click="handleForget">
					忘记密码
				</Button>
			</template>
	</Modal>
	<ForgetModel v-model="forget"></ForgetModel>
</template>

<script>
	import ForgetModel from '@/components/ForgetModel.vue'
	export default {
		data() {
			return {
				modal: false,
				loading: false,
				forget: false,
				closable: true,
				formData: {
					username: '',
					password: '',
				},
				ruleInline: {
					username: [{
						required: true,
						message: '用户名不能为空',
						trigger: 'blur'
					}],
					password: [{
							required: true,
							message: '密码不能为空',
							trigger: 'blur'
						},
						{
							type: 'string',
							min: 6,
							message: '密码不能少于6位',
							trigger: 'blur'
						}
					]
				}
			}
		},
		components: {
			ForgetModel
		},
		methods: {
			handleSubmit() {
				this.$refs['formData'].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.$axios.post('/user/login', {data:this.formData}).then((res) => {
							let data = res.data;
							if (data.code == 200) {
								this.modal = false;
								this.$store.commit('SetUser', data.data)
								this.$Message.success({
									content: data.msg,
									onClose: () => {
										this.$router.push('/center');
									}
								});
							} else {
								this.$Message.error({
									content: data.msg
								});
							}
							this.loading = false;
						});
					}
				})
			},
			handleReset() {
				this.$refs['formData'].resetFields();
				this.modal = false;
				this.loading = false;
				this.$router.push('/');
			},
			handleForget() {
				this.modal = false;
				this.forget = true
			}
		},
		computed: {
			IsLogin() {
				if (this.$store.state.jwt != '') {
					return true;
				} else {
					return false;
				}
			}
		},
		watch:{
			"$route.name":function(val){
				if(val == 'login'){
					this.modal = true;
					this.closable = false;
				}else{
					this.modal = false;
					this.closable = true;
				}
			}
			
		}
	}
</script>

<style>
	.login {
		width: 500px;
		height: 250px;
		padding: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
</style>
<template>
	<Header style="background-color: #2d8cf0;">
		<Menu mode="horizontal" theme="primary">
			<div class="layout-logo">
				<img src="./assets/logo.png" width="112" alt="">
			</div>
			<div class="layout-nav">
				<MenuItem name="1">
				<LoginModal rel="login"></LoginModal>
				</MenuItem>
				<MenuItem name="2">
				<RegisterModel></RegisterModel>
				</MenuItem>
				<MenuItem v-if="IsLogin" name="5">
				<Button type="info" @click="Logout">
					<Icon type="md-exit"></Icon>
					退出
				</Button>
				</MenuItem>
				<MenuItem v-if="IsLogin" name="4">
				<Button type="info" @click="gohome">
					<Icon type="md-person"></Icon>
					账号设置
				</Button>
				</MenuItem>
				<MenuItem name="3">
				<BuyCars></BuyCars>
				</MenuItem>
				<MenuItem v-if="IsLogin" name="6">
				欢迎回来 {{GetProfile.username}} Id:{{GetProfile.id}}
				</MenuItem>
				
			</div>
			<div class=".ivu-clearfix">
			</div>
		</Menu>
	</Header>
	<main>
		<router-view />
	</main>
	<footer class="ivu-text-center ivu-bt">
		<Row>
			<Col span="24">
			<p><b>添加客服微信可加入交流群 客服微信: waimao518666</b></p>
			<p>{{copyright}}</p>
			<p><img src="./assets/wx.8ec6ad77.png" alt="waimao518666" width="80"></p>
			</Col>
		</Row>
	</footer>
</template>

<style>
	#app .ivu-layout-header {
		height: auto;
	}

	.layout-logo {
		width: 200px;
		float: left;
		overflow: hidden;
	}

	.layout-logo {
		color: #fff;
		display: flex;
		float: left;
		margin-right: 20px;
		height: 60px;
		vertical-align: middle;
		justify-content: center;
		align-items: center;
	}

	.layout-nav {
		width: 550px;
		float: right;
		text-align: right;
	}


	.layout-nav .ivu-menu-item {
		font-size: 14px;
		float: right !important;
		padding: 0 10px !important;
	}

	.layout-nav .ivu-menu-item a,
	.layout-logo .ivu-menu-item a {
		color: #fff;
	}

	.layout-logo .ivu-menu-item {
		font-size: 14px;
		float: left !important;
		padding: 0 10px !important;
	}


	footer {
		background-color: #f8f8f9;
		padding: 30px 0 !important;
		line-height: 2em;
		width: 100%;
	}

	main {
		flex: 1;
		padding: 10px;
	}

	#app {
		min-height: 100%;
		display: flex;
		flex-direction: column;
	}

	html,
	body {
		height: 100% !important;
	}

	.ivu-card-head,
	.ivu-list-header {
		font-weight: bold;
		background-color: rgb(239, 245, 251) !important;
	}

	.ivu-card-head p span {
		font-size: 14px;
		font-weight: bold;
		color: #17233d;
	}

	.ivu-description-term {
		font-weight: bold;
	}
</style>

<script>
	import LoginModal from '@/components/LoginModel.vue'
	import RegisterModel from '@/components/RegisterModel.vue'
	export default {
		data() {
			return {
				copyright: 'Copyright © 2022 Waimao518 All Rights Reserved',
			}
		},
		components: {
			LoginModal,
			RegisterModel
		},
		computed: {
			GetProfile() {
				return this.$store.getters.profile;
			},
			IsLogin() {
				if (this.$store.state.jwt != '') {
					return true;
				} else {
					return false;
				}
			}
		},
		methods: {
			Logout() {
				this.$store.commit('Logout')
				this.$router.push('/')
			},
			gohome() {
				this.$Modal.info({
					title: '修改账号信息',
					content: '请登陆官方网站后，在用户中修改账号设置',
					onOk:()=>{
						window.electronAPI.openurl('https://www.waimao518.com')
					}
				});
			}
		}
	}
</script>
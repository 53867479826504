<template>
	<Button type="info" @click="modal = true" v-if="!IsLogin">
		<Icon type="ios-navigate"></Icon>
		注册
	</Button>
	<Modal v-model="modal" title="注册"  @on-cancel="handleReset">
		<Form ref="formItem" :model="formItem"  :rules="ruleInline" :label-width="80">
			<FormItem label="用户名" prop="username">
				<Input v-model="formItem.username" placeholder="请输入用户名"></Input>
			</FormItem>
			<FormItem label="手机" prop="tel">
				<Input v-model="formItem.tel" placeholder="请输入手机"></Input>
			</FormItem>
			<FormItem label="验证码" prop="code">
				<Input v-model="formItem.code" placeholder="短信验证码" style="width: 265px;"></Input>
				<Button class="sendcode" type="primary" @click="sendCode">发送手机验证码</Button>
			</FormItem>
			<FormItem label="密码" prop="password">
				<Input v-model="formItem.password" placeholder="请输入密码" type="password"></Input>
			</FormItem>
			<FormItem label="确认密码" prop="passcheck">
				<Input v-model="formItem.passcheck" placeholder="请输入密码" type="password"></Input>
			</FormItem>
			<FormItem label="邀请码" prop="inviter">
				<Input v-model="formItem.inviter" placeholder="邀请码" type="text"></Input>
			</FormItem>
		</Form>
		<template #footer>
			<Button type="primary" @click="handleSubmit" :loading="loading">
				提交
			</Button>
			<Button @click="handleReset">
				取消
			</Button>
		</template>
	</Modal>
</template>

<script>
	import Cookies from 'js-cookie'
	export default{
		data(){
			const validatePassCheck = (rule, value, callback) => {
				if (value !== this.formItem.password) {
					callback(new Error('确认密码与密码不一致，请重新输入'));
				} else {
					callback();
				}
			};
			const checkUsername = (rule, value, callback) => {
				this.$axios.post('/user/checkUsername',{username:this.formItem.username}).then((res)=>{
					if (!res.data) {
						callback(new Error('该用户名已经被占用'));
					} else {
						callback();
					}
				})
			}
			return {
				modal: false,
				loading: false,
				formItem: {
					username: '',
					password: '',
					passcheck:'',
					tel: '',
					code: '',
					inviter: Cookies.get('inviter') || ''
				},
				ruleInline: {
					username: [
						{ required: true, message: '用户名不能为空', trigger: 'blur' },
						{validator: checkUsername ,trigger: 'blur'}
					],
					password: [
						{ required: true, message: '密码不能为空', trigger: 'blur' },
						{ type: 'string', min: 6, message: '密码不能少于6位', trigger: 'blur' }
					],
					passcheck: [
						{ required: true, message: '密码不能为空', trigger: 'blur' },
						{ type: 'string', min: 6, message: '密码不能少于6位', trigger: 'blur' },
						{ validator: validatePassCheck, trigger: 'blur' }
						
					],
					tel: [
						{ required: true, message: '手机不能为空', trigger: 'blur' },
						{ type: 'string', pattern: /^\d{11}$/, message: '请输入正确的手机号', trigger: 'blur' },
					],
					code: [
						{ required: true, message: '手机验证码不能为空', trigger: 'blur' },
						{ type: 'string', len: 5, message: '请输入正确的手机验证码', trigger: 'blur' },
					]
				}
			}
		},
		methods: {
			handleSubmit() {
				this.$refs['formItem'].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.$axios.post('/user/register', this.formItem).then((res) => {
							let data = res.data;
							if(data.code == 200){
								this.modal = false;
								this.$Message.success({content:data.msg,onClose:()=>{this.$router.push('/');}});
							}else{
								this.$Message.error({content:data.msg});
							}
							this.loading = false;
						});
					}
				})
			},
			handleReset() {
				this.$refs['formItem'].resetFields();
				this.modal = false;
			},
			sendCode(){
				this.$refs['formItem'].validateField('tel',(valid)=>{
					if(valid == ''){
						this.$axios.post('/user/getcode', {tel:this.formItem.tel}).then((res) => {
							let data = res.data;
							if(data.code == 200){
								this.$Message.success({content:data.msg});
							}else{
								this.$Message.error({content:data.msg});
							}
						});
					}
				});
			}
		},
		computed: {
			IsLogin() {
				if(this.$store.state.jwt != ''){
					return true;
				}else{
					return false;
				}
			}
		},
	}
</script>

<style>
	.sendcode {
		position: absolute;
		right: 0;
	}
</style>